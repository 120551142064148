import axios from "axios";
import { cloneDeep } from "lodash";
import { getAvailableApps } from "../reducers/clientReducer";
import { getActiveWorkspaceForClient } from "../reducers/workspaceReducer";

/* -------------------- INITIAL STATE -------------------- */
const INITIAL_STATE = {
  customApps: [],
  searchCustomApps: [],
  loadingCustomApps: false,
  loadedCustomApps: false,
  loadingAppObjects: false,
  loadedAppObjects: false,
  loadingBaseApp: false,
  loadedBaseApp: false,
  availableCustomAppObjects: [],
  searchAppObjects: [],
  selectedCustomAppObjects: [
    {
      id: "-1",
      name: "Drop Here",
      path: "",
      rptId: "-1",
      title: "Drop Here",
      type: "drop"
    }
  ],
  selectedCustomApp: {},
  order: [],
  activeAppLoading: false
};

/* -------------------- ACTION TYPES -------------------- */
const FETCH_CUSTOM_APPS_BEGIN = "FETCH_CUSTOM_APPS_BEGIN";
const FETCH_CUSTOM_APPS_SUCCESS = "FETCH_CUSTOM_APPS_SUCCESS";
const FETCH_CUSTOM_APPS_FAILURE = "FETCH_CUSTOM_APPS_FAILURE";
const SET_AVAILABLE_OBJECTS = "SET_AVAILABLE_OBJECTS";
const SET_AVAILABLE_OBJECTS_BEGIN = "SET_AVAILABLE_OBJECTS_BEGIN";
const REORDER_CUSTOM_APPS = "REORDER_CUSTOM_APPS";
const FETCH_CUSTOM_APP_SUCCESS = "FETCH_CUSTOM_APP_SUCCESS";
const SET_SEARCH_APP = "SET_SEARCH_APP";
const SET_SEARCH_OBJS = "SET_SEARCH_OBJS";
const ADD_GROUPING = "ADD_GROUPING";
const SET_OBJS = "SET_OBJS";
const SET_SELECTED_APP_OBJS = "SET_SELECTED_APP_OBJS";
const SET_BASE_APP_BEGIN = "SET_BASE_APP_BEGIN";
const SET_BASE_APP_FAILURE = "SET_BASE_APP_FAILURE";
const SAVE_BASE_APP_BEGIN = "SAVE_BASE_APP_BEGIN";
const SET_SELECTED_APP = "SET_SELECTED_APP";

/* -------------------- ACTION CREATORS -------------------- */

const fetchCustomAppsBegin = () => ({
  type: FETCH_CUSTOM_APPS_BEGIN
});
const fetchCustomAppsSuccess = customApps => {
  return { type: FETCH_CUSTOM_APPS_SUCCESS, payload: customApps };
};

const fetchCustomAppsError = error => ({
  type: FETCH_CUSTOM_APPS_FAILURE,
  payload: { error }
});
const setAvailableObjects = (objs, groupings) => {
  return { type: SET_AVAILABLE_OBJECTS, payload: objs, groupings };
};
const setAvailableObjectsBegin = () => ({
  type: SET_AVAILABLE_OBJECTS_BEGIN
});

export const setApp = (customApp, order = []) => {
  return { type: FETCH_CUSTOM_APP_SUCCESS, payload: customApp, order };
};
const setSearchApp = apps => {
  return { type: SET_SEARCH_APP, payload: apps };
};
const setSearchObjs = objs => {
  return { type: SET_SEARCH_OBJS, payload: objs };
};
const setBaseAppBegin = () => ({
  type: SET_BASE_APP_BEGIN
});
const setBaseAppError = error => ({
  type: SET_BASE_APP_FAILURE,
  payload: { error }
});
const saveBaseAppBegin = () => ({
  type: SAVE_BASE_APP_BEGIN
});

/* -------------------- ASYNC ACTION CREATORS -------------------- */
export const getCustomApps = client => {
  return dispatch => {
    dispatch(fetchCustomAppsBegin());
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/getCustomApps`,
        {
          client
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(fetchCustomAppsSuccess(response.data.customApps));
      })
      .catch(error => dispatch(fetchCustomAppsError(error)));
  };
};
export const getBaseAppObjects = (client, appId, workspaceType) => {
  return dispatch => {
    dispatch(setBaseAppBegin());
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/powerbi/getBaseAppObjects`,
        {
          client,
          appId,
          workspaceType
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        if (response.data.title !== undefined) {
          let sasToken = response.data.sasToken;

          let thumbnails = response.data.thumbnails.map(item => {
            if (
              !item.link.includes(
                "tabsportal.blob.core.windows.net/public-folder/"
              )
            ) {
              item.link = item.link + "?" + sasToken;
            }
            return item;
          });

          dispatch(
            setApp(
              {
                title: response.data.title,
                alias: response.data.alias,
                description: response.data.description,
                icon: response.data.icon,
                type: response.data.type,
                link: response.data.link,
                collapsible: response.data.collapsible,
                id: response.data.id,
                thumbnails: thumbnails,
                maxReportTiles: response.data.maxReportTiles
              },
              []
            )
          );
        }
      })
      .catch(error => {
        console.log(error);
        dispatch(setBaseAppError(error));
      });
  };
};
export const getCustomAppObjects = (client, appId) => {
  return dispatch => {
    dispatch(setAvailableObjectsBegin());
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/powerbi/getCustomAppObjects`,
        {
          client,
          appId
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        if (response.data.title !== undefined) {
          let sasToken = response.data.sasToken;

          let thumbnails = response.data.thumbnails.map(item => {
            item.link = item.link + "?" + sasToken;
            return item;
          });

          dispatch(
            setApp(
              {
                title: response.data.title,
                alias: response.data.alias,
                description: response.data.description,
                icon: response.data.icon,
                groupPermissions: response.data.groupPermissions,
                id: response.data.id,
                thumbnails: thumbnails,
                maxReportTiles: response.data.maxReportTiles,
                showPrivateBookmarks: response.data.showPrivateBookmarks
              },
              response.data.order
            )
          );
        }

        dispatch(
          setAvailableObjects(response.data.reports, response.data.groupings)
        );
      })
      .catch(error => {
        console.log(error);
        dispatch(fetchCustomAppsError(error));
      });
  };
};
export const saveCustomApp = (client, customApp, order) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/saveCustomApp`,
        {
          client,
          customApp,
          order
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(getCustomApps(client));
      })
      .catch(error => {
        console.log(error);
        dispatch(fetchCustomAppsError(error));
      });
  };
};
export const saveBaseApp = (client, customApp, workspaceType) => {
  return dispatch => {
    dispatch(saveBaseAppBegin());
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/saveBaseApp`,
        {
          client,
          customApp,
          workspaceType
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(getCustomApps(client));
        dispatch(getActiveWorkspaceForClient(client));
        dispatch(getAvailableApps(client));
      })
      .catch(error => {
        console.log(error);
        dispatch(fetchCustomAppsError(error));
      });
  };
};
export const deleteCustomApp = (client, customApp) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/deleteCustomApp`,
        {
          customApp,
          client
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(getCustomApps(client.value));
      })
      .catch(error => {
        //console.log(error);
        //dispatch(fetchCustomAppsError(error));
      });
  };
};
export const createCustomApp = (client, appName) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/createCustomApp`,
        {
          client,
          appName
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(getCustomApps(client));
      })
      .catch(error => {
        console.log(error);
        dispatch(fetchCustomAppsError(error));
      });
  };
};

export const reorderItems = (startIndex, endIndex) => {
  return { type: REORDER_CUSTOM_APPS, startIndex, endIndex };
};
export const filterApps = (filter, apps) => {
  return dispatch => {
    if (filter === "") {
      dispatch(setSearchApp(apps));
    } else {
      let newApps = cloneDeep(apps);
      newApps = newApps.filter(app => {
        return app.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
      });
      dispatch(setSearchApp(newApps));
    }
  };
};
export const filterAvailableObjects = (filter, objs) => {
  let newObjs = [];

  return dispatch => {
    if (filter === "") {
      dispatch(setSearchObjs(objs));
    } else {
      objs.forEach((item, index) => {
        if (item.title === "Files") {
          if (item.title.toLowerCase().indexOf(filter.toLowerCase()) > -1) {
            newObjs.push(item);
          }
        } else {
          let procItem = item;
          if (procItem.children !== undefined && procItem.children.length > 0) {
            procItem.children = procItem.children.filter(rpt => {
              if (rpt.children === undefined) {
                return (
                  rpt.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1
                );
              } else {
                rpt.children = rpt.children.filter(child => {
                  if (child.fileType === "folder") {
                    child.children = child.children.filter(gChild => {
                      return (
                        gChild.name
                          .toLowerCase()
                          .indexOf(filter.toLowerCase()) !== -1
                      );
                    });
                    if (child.children.length > 0) {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return (
                      child.name.toLowerCase().indexOf(filter.toLowerCase()) !==
                      -1
                    );
                  }
                });
                if (rpt.children.length > 0) {
                  return true;
                } else {
                  return false;
                }
              }
            });

            newObjs.push(procItem);
          }
        }
      });

      dispatch(setSearchObjs(newObjs));
    }
  };
};
export const addGrouping = group => {
  return { type: ADD_GROUPING, payload: group };
};
export const setSearchAppObjects = searchAppObjs => {
  return { type: SET_OBJS, payload: searchAppObjs };
};
export const setSelectedAppObjects = objs => {
  return { type: SET_SELECTED_APP_OBJS, payload: objs };
};
export const setSelectedApp = obj => {
  return { type: SET_SELECTED_APP, payload: obj };
};

/* -------------------- REDUCER -------------------- */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CUSTOM_APPS_BEGIN:
      return { ...state, loadingCustomApps: true, error: null };
    case FETCH_CUSTOM_APPS_SUCCESS:
      return {
        ...state,
        customApps: action.payload,
        searchCustomApps: action.payload,
        loadingCustomApps: false,
        loadedCustomApps: true
      };

    case FETCH_CUSTOM_APPS_FAILURE:
      return {
        ...state,
        loadingCustomApps: false,
        error: action.payload.error
      };
    case SET_AVAILABLE_OBJECTS_BEGIN:
      return {
        ...state,
        loadingAppObjects: true,
        loadedAppObjects: false
      };
    case SET_AVAILABLE_OBJECTS:
      let sAppObjs = [];

      if (action.groupings !== undefined) {
        if (action.groupings.length > 0) {
          sAppObjs = [...action.groupings];
        }
      }
      let newPayload = cloneDeep(action.payload);
      newPayload.forEach(item => {
        if (item.title === "Files" && item.selected === true) {
          sAppObjs.push(item);
        } else if (item.type === "folder") {
          item.children.forEach(child => {
            if (child.type === "folder") {
              child.children = child.children.forEach(gChild => {
                if (gChild.type === "file" && gChild.selected === true) {
                  sAppObjs.push(gChild);
                } else if (gChild.type === "folder") {
                  gChild.children.forEach(ggChild => {
                    if (ggChild.type === "file" && ggChild.selected === true) {
                      sAppObjs.push(ggChild);
                    }
                  });
                }
              });
            }
            if (child.type === "file" && child.selected === true) {
              sAppObjs.push(child);
            }
          });
        } else if (item.type === "tableau") {
          item.expanded = true;
          item.children.forEach(child => {
            if (child.selected === true) {
              sAppObjs.push(child);
            }
          });
        }
      });

      var c = [];
      state.order.forEach(el =>
        c.push(
          sAppObjs.find(e => {
            if (e.type === "Files") {
              return e.type === el;
            } else if (e.type === "groupings") {
              //debugger;
              return e.id === el;
            } else {
              return e.id === el;
            }
          })
        )
      );
      c = c.filter(item => {
        return item !== undefined;
      });
      // console.log("SET AVAILABLE OBJECTS");
      if (c.length === 0) {
        return {
          ...state,
          availableCustomAppObjects: action.payload,
          searchAppObjects: action.payload,
          loadingAppObjects: false,
          loadedAppObjects: true
        };
      } else {
        return {
          ...state,
          availableCustomAppObjects: action.payload,
          searchAppObjects: action.payload,
          loadingAppObjects: false,
          loadedAppObjects: true,
          selectedCustomAppObjects: c
        };
      }
    case FETCH_CUSTOM_APP_SUCCESS:
      return {
        ...state,
        order: action.order,
        selectedCustomApp: action.payload
      };
    case SET_SELECTED_APP:
      return {
        ...state,
        selectedCustomApp: action.payload
      };
    case REORDER_CUSTOM_APPS:
      //let newAppOrder = state.order;
      const result = Array.from(state.order);
      const [removed] = result.splice(action.startIndex, 1);
      result.splice(action.endIndex, 0, removed);

      return { ...state, order: result };
    case SET_SEARCH_APP:
      return { ...state, searchCustomApps: action.payload };
    case SET_SEARCH_OBJS:
      return { ...state, searchAppObjects: action.payload };
    case ADD_GROUPING:
      // console.log("ADDING GROUPING");
      let gAppObjects = cloneDeep(state.selectedCustomAppObjects);
      // let gOrder = cloneDeep(state.order);
      // if (gAppObjects.groupings !== undefined) {
      //   if (gAppObjects.groupings.length >= 1) {
      //     gAppObjects.groupings.push(action.payload);
      //   } else {
      //     gAppObjects.groupings = [action.payload];
      //   }
      // } else {
      //   gAppObjects.groupings = [action.payload];
      // }
      gAppObjects.push(action.payload);
      //gOrder.push(action.payload.id);
      //debugger;
      return {
        ...state,
        selectedCustomAppObjects: gAppObjects
      };
    case SET_OBJS:
      let newCustomAppObjects = state.availableCustomAppObjects.map(item => {
        if (item.children !== undefined) {
          item.children = item.children.map(child => {
            if (child.type === "folder") {
            } else if (child.type === "file") {
              action.payload.forEach(aItem => {
                if (item.id === aItem.id) {
                  aItem.children.forEach(aChild => {
                    if (aChild.id === child.id) {
                      child.selected = aChild.selected;
                    }
                  });
                }
              });
            }

            return child;
          });
        }
        return item;
      });
      return {
        ...state,
        searchAppObjects: action.payload,
        availableCustomAppObjects: newCustomAppObjects
      };
    case SET_SELECTED_APP_OBJS:
      let newObjs = [];

      action.payload.forEach(item => {
        if (
          item.type !== "grouping" &&
          item.children !== undefined &&
          item.children.length > 0
        ) {
          item.children.forEach(gChild => {
            newObjs.push(gChild);
          });
        } else {
          newObjs.push(item);
        }
      });
      newObjs = newObjs.filter((item, index, self) => {
        if (item.name === "Drop Here") {
          return false;
        }
        return (
          index ===
          self.findIndex(t => {
            return t.id === item.id;
          })
        );
      });
      newObjs = newObjs.map(item => {
        if (item.type === "grouping") {
          if (item.children !== undefined) {
            if (item.children.length > 1) {
              item.children = item.children.filter(child => {
                return child.type !== undefined;
              });
            }
            item.children.forEach((child, idx) => {
              let children = cloneDeep(child.children);

              if (children === undefined) {
              } else if (children.length > 0) {
                item.children.splice(idx, 1);

                children.forEach(gChild => {
                  gChild.selected = true;
                  item.children.push(gChild);
                });
              } else {
                child.groupId = item.id;
                if (child.type !== undefined) {
                  if (!child.type.includes("Groupings")) {
                    child.type = child.type + "Groupings";
                  }
                  //sets the selected value for the right available objects box through magic of reference
                  child.selected = true;
                }
                item.children.push(child);
              }
            });
            item.children = item.children.map(child => {
              child.groupId = item.id;
              if (child.type !== undefined) {
                if (!child.type.includes("Groupings")) {
                  child.type = child.type + "Groupings";
                }
                //sets the selected value for the right available objects box through magic of reference
                child.selected = true;
              }
              return child;
            });
          } else {
            item.children = [];
          }
        }
        item.selected = true;
        return item;
      });
      // console.log("SET_SELECTED_APP_OBJS");

      if (newObjs.length === 0) {
        newObjs = [
          {
            id: "-1",
            name: "Drop Here",
            path: "",
            rptId: "-1",
            title: "Drop Here",
            type: "drop"
          }
        ];
      }
      return {
        ...state,
        selectedCustomAppObjects: newObjs
      };
    case SAVE_BASE_APP_BEGIN:
      return { ...state, loadingCustomApps: true };
    default:
      return state;
  }
};
